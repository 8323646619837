



import * as React from "react"
import Layout from "../components/layout"


const ContactPage = () => {
    return (
        <Layout pageTitle="Contact Us">

<p>In choosing Thornley Associates, licensed General Contractor and Owners Representatives with over 40 years of residential and commercial project successes in the Napa-Sonoma region, you are assured of an exceptional process and outcome.</p>



<p>CA License #1000620</p>



<p>Based:    Sonoma, California</p>

<p>Mobile:   707.227.9920</p>

<p>Email:    <a href="mailto:info@thornleyassociates.com" target="_blank" rel="noreferrer">info@thornleyassociates.com</a></p>




        </Layout>

    )
}

export default ContactPage
